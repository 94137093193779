import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import {
  CustomSection,
  CustomSectionContent,
  CustomSectionStickyHeading,
  CustomSectionStickyHeadingInner,
  CustomSectionStickyItemsWrap,
  CustomSectionItem,
} from '../../components/CustomSection';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import StickySidebar from '../../components/StickySidebar';
import CTA from '../../components/CTA';

import './index.scss';

const AboutUsPage = () => (
  <Layout
    pageWrapperClassName="about-us-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="About Us | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content: 'Get to know us better: who we are, what we do, and why and how exactly we do it. Also, find out what our writers like about their jobs. If you feel we are the right people to write your assignment, then place an order, and we’ll do our best to help you.',
        },

        { property: 'og:title', content: 'About Us | TakeAwayEssay.com' },
        {
          property: 'og:description',
          content: 'Get to know us better: who we are, what we do, and why and how exactly we do it. Also, find out what our writers like about their jobs. If you feel we are the right people to write your assignment, then place an order, and we’ll do our best to help you.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/about-us.html' },
      ]}
    />

    <CustomSection className="custom-section_about-us">
      <CustomSectionContent hasStickyElement>
        <CustomSectionStickyHeading className="about-us-section__heading">
          <StickySidebar topSpacer={169}>
            <CustomSectionStickyHeadingInner className="about-us-section__heading__inner">
              <VerticalTextBlock
                sectionClassName="about-us"
                text="About Us"
                dotsStyle="style-4"
              />
              <div className="about-us-section__heading__box">
                <h2 className="about-us-section__heading__h2">
                  About us
                </h2>
                <p className="about-us-section__heading__paragraph">
                  It all started back in 2018. We were a bunch of writers, programmers,
                  and marketing specialists who had nothing but a plan to
                  launch a custom writing service. A lot has changed since then — we had our ups and
                  downs, we learned new ways to satisfy our
                  customers — but our main goals remained the same: to help students get
                  better grades by providing them with quality custom samples.
                </p>

                <span className="about-us-section__heading__circle about-us-section__heading__circle_one" />
                <span className="about-us-section__heading__circle about-us-section__heading__circle_two" />
                <span
                  className="about-us-section__heading__circle about-us-section__heading__circle_three"
                />
              </div>
            </CustomSectionStickyHeadingInner>
          </StickySidebar>
        </CustomSectionStickyHeading>
        <CustomSectionStickyItemsWrap className="about-us__item-wrap">
          <div className="about-us__item-wrap__heading">
            <h2 className="about-us__item-wrap__heading__h2">
              Meet our writers
            </h2>
            <p>
              We could have described our writers with words like experienced, enthusiastic, or
              motivated. But this wouldn’t be enough to
              describe what kind of people they are. So, instead we decided to let our writers tell
              us about their job.
            </p>
          </div>

          <CustomSectionItem className="about-us__item">
            <div className="about-us__item__heading">
              <div className="about-us__item__img">
                <img
                  data-observed-image
                  data-src="/img/about-us/photo-heater@2x.png"
                  width={148}
                  height={146}
                  alt="heater"
                />
                <img
                  data-observed-image
                  data-src="/img/icons/bubble-61.svg"
                  alt="bubble"
                  className="about-us__item__img-bubble"
                />
              </div>
              <div className="about-us__item__heading__text">
                <span>
                  Heather Stewart
                </span>
                {' '}
                25
              </div>
            </div>

            <p className="about-us__item__comment">
              “My principal motivation has always been to do a job well. If I take a writing order
              or any
              other responsibility, I try my best to deliver it within the designated time.”
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="about-us__item">
            <div className="about-us__item__heading">
              <div className="about-us__item__img">
                <img
                  data-observed-image
                  data-src="/img/about-us/photo-keith@2x.png"
                  width={157}
                  height={158}
                  alt="keith"
                />
                <img
                  data-observed-image
                  data-src="/img/icons/bubble-62.svg"
                  alt="bubble"
                  className="about-us__item__img-bubble"
                />
              </div>
              <div className="about-us__item__heading__text">
                <span>
                  Keith Hughes
                </span>
                {' '}
                28
              </div>
            </div>
            <p className="about-us__item__comment">
              “My writing skills are a result of hard work and regular practice. I always try to
              learn new
              things and improve my writing skills.”
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="about-us__item">
            <div className="about-us__item__heading">
              <div className="about-us__item__img">
                <img
                  data-observed-image
                  data-src="/img/about-us/photo-ian@2x.png"
                  width={155}
                  height={146}
                  alt="ian"
                />
                <img
                  data-observed-image
                  data-src="/img/icons/bubble-63.svg"
                  alt="bubble"
                  className="about-us__item__img-bubble"
                />
              </div>
              <div className="about-us__item__heading__text">
                <span>
                  Ian Clarke
                </span>
                {' '}
                40
              </div>
            </div>
            <p className="about-us__item__comment">
              “I like to write about business, as this is my degree. However, I also like political
              science,
              anthropology, and health care. By the way, I once worked on an order requiring me to
              write a horror story, and this was quite
              unusual for me.”
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="about-us__item">
            <div className="about-us__item__heading">
              <div className="about-us__item__img">
                <img
                  data-observed-image
                  data-src="/img/about-us/photo-ashley@2x.png"
                  width={150}
                  height={155}
                  alt="ashley"
                />
                <img
                  data-observed-image
                  data-src="/img/icons/bubble-64.svg"
                  alt="bubble"
                  className="about-us__item__img-bubble"
                />
              </div>
              <div className="about-us__item__heading__text">
                <span>
                  Ashley Murphy
                </span>
                {' '}
                33
              </div>
            </div>
            <p className="about-us__item__comment">
              “There are several principles I follow: organize and plan ahead; treat your deadline
              as
              sacrosanct and plan; also keep in mind that the customer is depending on you to get
              good grades, so give it your best shot.”
            </p>
          </CustomSectionItem>
        </CustomSectionStickyItemsWrap>
      </CustomSectionContent>
    </CustomSection>

    <CustomSection className="custom-section_contact-us">
      <CustomSectionContent hasStickyElement>
        <CustomSectionStickyHeading className="contact-us-section__heading">
          <StickySidebar topSpacer={169}>
            <CustomSectionStickyHeadingInner className="contact-us-section__heading__inner">
              <VerticalTextBlock
                sectionClassName="contact-us"
                dotsStyle="style-4"
                text="Contact Us"
              />
              <div className="contact-us-section__heading__box">
                <h2 className="contact-us-section__heading__h2">
                  Contact us
                </h2>
                <p className="contact-us-section__heading__paragraph">
                  Finding common ground is important for the sake of good results. So,
                  we have listed all the possible ways to get in touch with us.
                </p>
                <span
                  className="contact-us-section__heading__circle contact-us-section__heading__circle_one"
                />
                <span
                  className="contact-us-section__heading__circle contact-us-section__heading__circle_two"
                />
                <span
                  className="contact-us-section__heading__circle contact-us-section__heading__circle_three"
                />
                <span
                  className="contact-us-section__heading__circle contact-us-section__heading__circle_four"
                />
              </div>
            </CustomSectionStickyHeadingInner>
          </StickySidebar>
        </CustomSectionStickyHeading>
        <CustomSectionStickyItemsWrap className="contact-us__item-wrap">
          <CustomSectionItem className="contact-us__item">
            <h3 className="contact-us__item__heading contact-us__item__heading_location">
              Our location
            </h3>
            <p
              className="hidden-info"
              data-title="Writera Limited, Vasili Michalidi, 9, 3026, Limassol, Cyprus"
            />
            <p
              className="hidden-info"
              data-title="Company number: ΗΕ 406826"
            />
          </CustomSectionItem>
          <CustomSectionItem className="contact-us__item">
            <h3 className="contact-us__item__heading contact-us__item__heading_phone">
              Phone
            </h3>
            <p>
              Tel:
              {' '}
              <a href="tel:+18552605455" className="contact-us__item__link">
                1-855-260-5455
              </a>
            </p>
            <p>
              Phone working hours: Monday 00:00 AM – Saturday 08:00 PM (UTC).
            </p>
            <p>
              Toll-free for US & Canada (1-855…) only. International callers are
              charged for incoming calls.
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="contact-us__item">
            <h3 className="contact-us__item__heading contact-us__item__heading_chat">
              Chat
            </h3>
            <p>
              Chat working hours: Monday 00:00 AM – Saturday 08:00 PM (UTC).
            </p>
          </CustomSectionItem>
          <CustomSectionItem className="contact-us__item">
            <h3 className="contact-us__item__heading contact-us__item__heading_email">
              Email
            </h3>
            <p>
              info@takeawayessay.com
            </p>
          </CustomSectionItem>
        </CustomSectionStickyItemsWrap>
      </CustomSectionContent>
    </CustomSection>

    <CTA isOnlyWaves />

  </Layout>
);

export default AboutUsPage;
